import React from 'react';
import store from 'store';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import validURL from '../../utils/validURL';

// Helpers
import config from '../../config';
import styles from './Button.module.scss';

//
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Button = ({ children, className, disabled, history, href, onClick, type, hover, id, style, dataCy, target }) => {
    const isPWA = store.get(config.user.isPWA);
    const _onClick = (e) => {
        e.preventDefault();

        if (onClick) onClick();
        if (href) validURL(href) ? (window.location = href) : history.push(href);
    };

    if (href) {
        if(validURL(href)) {
            return (
                <a
                    id={id}
                    href={href}
                    style={style}
                    target={target}
                    data-cy={dataCy}
                    className={classnames(styles.button, type && styles[type], hover && styles[hover], isPWA && styles.pwa, className)}
                >
                    {children}
                </a>
            )

        }
        return (
            <LinkContainer
                id={id}
                to={href}
                style={style}
                target={target}
                data-cy={dataCy}
                className={classnames(styles.button, type && styles[type], hover && styles[hover], isPWA && styles.pwa, className)}
            >
                <Nav.Link eventKey="">
                    {children}
                </Nav.Link>
            </LinkContainer>
        );
    } else {
        return (
            <button
                id={id}
                type="button"
                style={style}
                data-cy={dataCy}
                disabled={disabled}
                onClick={disabled ? '' : (e) => _onClick(e)}
                className={classnames(styles.button, type && styles[type], hover && styles[hover], isPWA && styles.pwa, className)}
            >
                {children}
            </button>
        );
    }
};

export default withRouter(Button);

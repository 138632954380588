import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import store from 'store';
import config from '../../config';
import styles from './Footer.module.scss';

// Components
import regulation from "../../assets/pdf/referralRegulation.pdf";

const Footer = () => {
    const isPWA = store.get(config.user.isPWA);
    const isLoggedIn = store.get(config.user.token);
    const [secondWave,setSecondWave] = useState(false);

    useEffect(()=>{
        if(window.location.href.indexOf('gratuit') !== -1) {  // check if the url is first or second wave for different paths
            setSecondWave(true);
        }
    },[])


    return (
        <>
            <div className={styles.footer}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <h4>Linkuri utile</h4>

                        <ul>
                            <li>
                                <a href={secondWave ? config.path.map2 :config.path.map}>
                                    Găsește IQOS
                                </a>
                            </li>
                            <li>
                                <a href= 'https://www.iqos.ro/noutati' target="_blank" rel="noopener noreferrer">
                                    Noutăți
                                </a>
                            </li>
                            <li>
                                <a href={config.path.shopIQOS} target="_blank" rel="noopener noreferrer">
                                    IQOS Shop
                                </a>
                            </li>
                            <li>
                                <a href="https://www.iqos-on.ro/" target="_blank" rel="noopener noreferrer">
                                    IQOS ON
                                </a>
                            </li>
                            <li><a href="https://www.pmi.com" target="_blank" rel="noopener noreferrer">Accesează PMI.com</a></li>
                        </ul>
                    </div>

                    <div className={styles.column}>
                        <h4>Asistență clienți</h4>

                        <ul>
                            <li>
                                <a href="https://www.iqos.ro/contact"> Contactează-ne</a>
                            </li>
                            <li>
                                <a href={regulation} target="_blank">
                                    Regulament
                                </a>
                            </li>
                            <li>
                                <a href="https://www.iqos.ro/garantie-iqos">Înlocuire IQOS</a>
                            </li>
                            <li>
                                <a href="https://www.iqos.ro/asistenta/iqos/3-duo">Tutoriale</a>
                            </li>
                        </ul>
                    </div>

                    <div className={classnames(styles.column, styles.isWide)}>
                        <h4>Legal</h4>

                        <ul>
                            <li><a href="http://www.anpc.gov.ro" target="_blank" rel="noopener noreferrer">ANPC</a></li>
                            <li>
                                <a href={config.path.termsConditions}>
                                    Termeni și Condiții
                                </a>
                            </li>
                            <li>
                                <a href={config.path.termsConditionsUGC}>Termeni și Condiții "TU si IQOS"</a>
                            </li>
                            <li>
                                <a href={config.path.userDataNotification}>
                                    Notificare de protecție a datelor
                                </a>
                            </li>

                            <li>
                                <a href={config.path.cookiesPolicy}>
                                    Politică de cookies
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className={classnames(styles.column, styles.pullRight, styles.small)}>
                        <h4>Urmărește-ne</h4>

                        <ul>
                            <li>
                                <a href={'https://www.facebook.com/iqos.ro'} target="_blank" rel="noopener noreferrer">
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href={'https://twitter.com/iqos_support_ro'} target="_blank" rel="noopener noreferrer">
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href={'https://www.instagram.com/iqos_ro'} rel="noopener noreferrer">
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;

import React from 'react';
import config from '../../config';
import axios from '../../utils/axios';
import { Helmet } from 'react-helmet';
import styles from './Link4Refferal.module.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import directive from './images/health_warning.png';

//components
import FdaSection from '../Referral/Components/fdaSection/fdaSection';
import IqosReasons from './Components/IqosReasons/IqosReasons';
import IqosBenefits from './Components/IqosBenefits/IqosBenefits';
import Layout from '../../components/Layout';
import Form from '../Form/Form';
import EducatedAwarenessComponent from "../../components/EducatedAwarenessComponent/EducatedAwarenessComponent";

//redux
import CountiesAction from '../../stores/Counties/Actions';
import ReferralAction from '../../stores/Referral/Actions';


class Link4Refferal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: {
				phone_number: '',
				email: '',
				first_name: '',
				last_name: '',
				county: '',
				city: '',
				token: '',
				adultSmoker: ''
			},
			selectedCounty: {
				id: null,
				name: null,
				cities: []
			},
			termsAccepted: false,
			isSuccess: false,
			isLoading: true,
			showFormPopup: false,
			counties: null,
			cities: null,
			token: '',
			errorMessage: '',
			slug: null
		};
	}

	componentDidMount() {
		this.props.getCounties();
		this.checkToken();
		window.scrollTo(0, 0)
	}

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}
	checkToken = () => {
		const token = this.props.match.params.token;
		if (window.location.href.indexOf('gratuit') !== -1) {
			this.setState({ slug: 'gratuit' });
		}
		if (token) {
			axios
				.get(`/api/check-token/${token}`, {
					params: {
						slug: null
					}
				})
				.then(response => {
					localStorage.setItem('token', token);
					this.setState({ token: token });
				})
				.catch(error => {
					this.props.history.push(config.path.notFound);
					localStorage.removeItem('token');
				});
		} else {
			window.location = config.path.notFound;
		}
	};

	toggleModal = event => {
		event && event.preventDefault();

		this.setState({
			showFormPopup: !this.state.showFormPopup
		});
	};

	submit = () => {
		const { formData } = this.state;
		let token = localStorage.getItem('token');

		axios
			.post(`api/register`, {
				first_name: formData.first_name,
				last_name: formData.last_name,
				phone_number: formData.phone_number,
				email: formData.email,
				county_id: formData.county,
				city_id: formData.city,
				token: token
			})
			.then(response => {
				this.setState({
					showFormPopup: !this.state.showFormPopup,
					isSuccess: true
				});
			})
			.catch(error => {
				this.setState({
					errorMessage:
						error.response.data.errorMessage ||
						(error.response.data.message &&
							'Te rugăm să introduci datele valide și să te asiguri că ai completat toate câmpurile'),
					showFormPopup: !this.state.showFormPopup
				});
			});
	};

	render() {
		const { isPWA, errorMessage } = this.state;

		return (
			<div className={styles.fullPage}>
				<Layout>
					<Helmet>
						<title>{`Fii primul care încearcă | ${
							isPWA ? `PWA` : `IQOS Club`
						}`}</title>
						<meta name="robots" content="index, follow" />
						<meta name="description" content="" />
						<meta name="keywords" content="" />
					</Helmet>
					<div
						className={classnames(
							'page_heading',
							'isReferral',
							isPWA ? 'isPWA.isReferral' : '',
							'hasSubheading'
						)}
					>
						<div className='rowHeading'>
							<div className='textContainer'>
								<div className='detailsHeader'>
									<h2>Același ritual.</h2>
									<h2>Aceeași comunitate.</h2>

								</div>
								{/*{index === 1 && <Button noLink type='primary' onClick={() => this.handleTabChange(0)}> Descoperă avantajele IQOS </Button>}*/}
							</div>
							<div className= 'imageHeader' />
						</div>
					</div>
					<div className={styles.container}>
						<h2>Salut!</h2>
						<p>
							Ne pare bine că ai fost recomandat de un prieten să încerci IQOS. <br/>
							Descoperă-i avantajele și bucură-te de experiență!
						</p>
					</div>
					<div className={styles.formSection}>
						<h2>Împarte aceeași emoție în aceeași comunitate.</h2>
						<p>
							Bucură-te de emoția de a fi parte din comunitatea IQOS și achiziționează un <b>Starter Kit IQOS</b>, compus dintr-un dispozitiv IQOS și trei pachete HEETS cadou.
						</p>
						<Form />
					</div>
					<IqosReasons />
					<div className={styles.container}>
						<h2>
							Intră în comunitate și profită de beneficiile pe care le-ai putea
							avea ca membru IQOS CLUB
						</h2>
						<IqosBenefits />
					</div>
					{/*<FdaSection />*/}
					<EducatedAwarenessComponent/>
					<h3>Te așteptăm în lumea IQOS!</h3>
				</Layout>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	counties: state.counties.counties,
	cities: state.counties.cities
});

const mapDispatchToProps = {
	getCounties: () => CountiesAction.counties(),
	getCities: id => CountiesAction.cities(id),
	citiesReset: () => CountiesAction.citiesReset(),
	showModal: formData => ReferralAction.referralModal(formData)
};

export default connect(mapStateToProps, mapDispatchToProps)(Link4Refferal);

import React from 'react';
import ReeValidate from 'ree-validate';
import axios from '../../utils/axios';
import styles from './Form.module.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';

//assets
import checked from './assets/checked.svg';
import wrong from './assets/wrong.svg';
import teak from '../Referral/Benefits/images/teak.png';
import closeImg from '../../assets/images/icons/closeImg.png';

//components
import { TextInput } from './FormInput';
import { InputWithAutocomplete, InputWithMask } from '../../components/Input';
import Button from '../../components/ButtonV3/Button';
import Popup from 'reactjs-popup';
import Loader from '../../components/Loader';

//redux
import CountiesAction from '../../stores/Counties/Actions';
import ReferralAction from '../../stores/Referral/Actions';


class Form extends React.Component {
	constructor(props) {
		super(props);

		let dictionary = {
			en: {
				messages: {
					required: () => 'Acest câmp este obligatoriu.',
					email: () => 'Te rugăm să introduci o adresă de email validă.',
					max: (field, value) =>
						`Te rugăm să introduci ${value} caractere.`,
					min: (field, value) =>
						`Te rugăm să introduci ${value} caractere.`,
					numeric: () => 'Număr telefon invalid.',
					alpha: () => 'Caracterele numerice nu sunt permise.'
				}
			}
		};

		this.validator = new ReeValidate({
			first_name: 'required|max:100|min:3',
			last_name: 'required|max:100',
			phone_number: 'required|numeric|max:10|min:10',
			email: 'required|email',
			street: 'required|max:100',
			street_no: 'required|max:100',
			county: 'required',
			city: 'required',
			adultSmoker: 'required'
		});

		this.validator.localize(dictionary);

		this.state = {
			validations: {
				first_name: 'required|max:100|min:3',
				last_name: 'required|max:100',
				phone_number: 'required|numeric|max:10|min:10',
				email: 'required|email',
				county_id: 'required',
				city_id: 'required',
				adultSmoker: 'required'
			},

			formData: {
				phone_number: '',
				email: '',
				first_name: '',
				last_name: '',
				county: '',
				city: '',
				token: '',
				adultSmoker: ''
			},
			selectedCounty: {
				id: null,
				name: null,
				cities: []
			},
			termsAccepted: false,
			isSuccess: false,
			isLoading: true,
			errors: this.validator.errors,
			showFormPopup: false,
			counties: null,
			cities: null,
			token: '',
			errorMessage: '',
			usedData: ''
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.props.getCounties();
		this.setState({isLoading: false})
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.errors && prevProps.errors !== this.props.errors) {
			this.setState({isSuccess: false})
		}
		if(this.state.errorMessage && prevState.errorMessage !== this.state.errorMessage) {
			this.setState({isSuccess: false})
		}
	}

	onCancel = () => {
		this.setState({
			errorMessage: null
		});
		if(this.state.isSuccess) {
			window.location.reload()
		}
	};

	//CALLED RESET METHOD TO EMPTY THE CITIES ARRAY WHEN USER CHANGES THE INPUT BY PRESSING OR DELETING LETTERS
	handleKeyPress = () => {
		this.props.citiesReset();
		this.setState(state => ({
			...state,
			formData: {
				...state.formData,
				city: null
			}
		}));
	};

	handleChange = (field, value) => {
		const { errors } = this.validator;
		const { counties } = this.props;
		errors.remove(field);

		value = field === 'smoker' && value === false ? '' : value;

		if (field === 'county') {
			errors.remove('city');
			let countyObj;
			counties.map(item => {
				if (item.id === value) {
					countyObj = item;
				}
			});

			if (countyObj) {
				this.props.getCities(countyObj.id); //CALLED CITIES BASED ON COUNTY SELECTED
			} else
				this.setState(state => ({
					...state,
					selectedCounty: { ...state.selectedCounty, cities: [] }
				}));
		}
		this.setState(state => ({
			...state,
			formData: {
				...state.formData,
				[field]: value
			}
		}));

		this.validator.validate(field, value).then(() => {
			this.setState({ errors });
		});
	};

	toggleModal = event => {
		event && event.preventDefault();

		this.setState({
			showFormPopup: !this.state.showFormPopup
		});
	};
	toggleAdultSmoker = event => {
		event && event.preventDefault();
		this.setState({
			termsAccepted: !this.state.termsAccepted
		});
	};

	submit = () => {
		const { formData } = this.state;
		const { errors } = this.validator;

		let token = localStorage.getItem('token');
		this.setState({isLoading:true})

		this.validator.validateAll(formData).then(success => {
			if (success) {
				this.setState({ showFormPopup: !this.state.showFormPopup, isSuccess: true,isLoading:true});
			} else {
				this.setState({ errors });
			}
		});

		axios
			.post(`api/register`, {
				first_name: formData.first_name,
				last_name: formData.last_name,
				phone_number: formData.phone_number,
				email: formData.email,
				county_id: formData.county,
				city_id: formData.city,
				token: token
			})
			.then(response => {
				this.setState({
					showFormPopup: !this.state.showFormPopup,
					isSuccess: true,
					isLoading: false
				});
			})
			.catch(error => {
					this.setState({
					errorMessage:
						error.response.data.errorMessage || (error.response.data.message && 'Te rugăm să introduci datele valide și să te asiguri că ai completat toate câmpurile' ),
					showFormPopup: !this.state.showFormPopup,
					isLoading: false
				});
			});
	};

	render() {
		const { termsAccepted, errorMessage, formData, isSuccess,isLoading } = this.state;
		const { counties,loading } = this.props;
		const { errors } = this.validator;
		if (loading) {
			return (
				<form className={styles.formContainer}>
					<Loader/>
				</form>
			);
		}
		return (
			<div>
				{isLoading ? <Loader /> :
				<div className={styles.formContainer}>
					<div className={styles.formInputs}>
						<div className={styles.split}>
							<TextInput
								id="first_name"
								title="* Prenume"
								placeholder="ex: George"
								value={this.state.formData.first_name}
								errors={this.state.errors}
								handleChange={this.handleChange}
							/>

							<TextInput
								id="last_name"
								title="* Nume"
								placeholder="ex: Popescu"
								value={this.state.formData.last_name}
								errors={this.state.errors}
								handleChange={this.handleChange}
							/>
						</div>

						<div className={styles.split}>
							<InputWithMask
								id="phone_number"
								title="* Număr de telefon"
								placeholder="ex: 07xx xxx xxx"
								value={this.state.formData.phone_number}
								errors={this.state.errors}
								handleChange={this.handleChange}
								mask="0\799999999"
							/>

							<InputWithMask
								id="email"
								title="* Email"
								placeholder="ex: popescu@gmail.com"
								value={this.state.formData.email}
								errors={this.state.errors}
								handleChange={this.handleChange}
							/>
						</div>
						<div className={styles.split}>
							{counties && (
								<InputWithAutocomplete
									placeholder = 'Alege judeţul'
									isFirstInput
									id="county"
									title="* Județ"
									value={this.state.formData.county}
									handleChange={this.handleChange}
									handleKeyPress={this.handleKeyPress}
									errors={errors}
									arr={counties}
									objKey="county"
								/>
							)}
							{((this.props.cities && this.props.cities.length > 0) || formData.city) && ( //CHECK IF THERE ARE CITIES OR THERE IS A SELECTED CITY IN SUBMIT FORM
								<InputWithAutocomplete
									placeholder='Alege oraşul'
									id="city"
									title="* Oraș"
									value={this.state.formData.city}
									handleChange={this.handleChange}
									errors={errors}
									arr={this.props.cities}
									objKey="city"
								/>
							)}
						</div>

						<label
							className={classnames(
								'control control--checkbox checkboxTerms',
								styles.label
							)}
						>
							<input
								type="checkbox"
								checked={termsAccepted}
								onChange={this.toggleTerms}
								onClick={() => {
									this.toggleAdultSmoker();
								}}
							/>
							<div className="control__indicator" />
							*Sunt fumător, am împlinit 18 ani, iar datele personale furnizate
							de mine în programul IQOS CLUB vor fi folosite pentru a putea fi
							contactat de un reprezentant de vânzări IQOS.
						</label>
					</div>

					<img src={teak} alt="" />
					<Popup
						modal
						closeOnDocumentClick
						open={this.state.showFormPopup}
						onClose={() => {
							this.toggleModal();
							this.onCancel();
						}}
						// contentStyle={{ maxWidth: '70%' }}
					>
						{close => (
							<div className={styles.popupContent}>
								<div className={styles.popupMessage}>
									<img src={closeImg} onClick={close} alt="close-popup" className={styles.closeImg}/>
									{errorMessage ? (
										<div className={styles.formResponse}>
											<img src={wrong} alt="wrong"/>
											<h2 dangerouslySetInnerHTML={{ __html: errorMessage }}/>
										</div>
										) : isSuccess && (
										<div className={styles.formResponse}>
											<img src={checked} alt="checked"/>
											<h2>Te-ai înscris cu succes!</h2>
											<p>Un reprezentant IQOS te va contacta în curând.</p>
											<p>Îți multumim!</p>
										</div>
									)}
								</div>
							</div>
						)}
					</Popup>
				</div>}
				<div className={styles.submit}>
					<Button
						noLink
						disabled={!termsAccepted}
						onClick={e => {
							this.submit(e);
							!errors && this.toggleModal(e);
						}}
						type="primary"
					>
						Înscrie-te
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.counties.loading,
	counties: state.counties.counties,
	cities: state.counties.cities
	// formErrors: state.referral.formErrors
});

const mapDispatchToProps = {
	getCounties: () => CountiesAction.counties(),
	getCities: (id) => CountiesAction.cities(id),
	citiesReset: () => CountiesAction.citiesReset(),
	showModal: (formData) => ReferralAction.referralModal(formData)
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);